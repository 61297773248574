<template>
  <div>
    <v-card>
      <div class="pa-5">
        <v-row class="flex-wrap">
          <v-col
            cols="4"
          >
            <v-card-title
              primary-title
              class="title"
            >
              Utilisateurs
            </v-card-title>
          </v-col>           
          <v-col align="right">
            <v-btn
              class="mr-2"
              color="success"
            >
              <v-icon>mdi-file-download-outline</v-icon>
              <JsonCSV 
                :data="getUsersForCsv"
                name="liste-utilisateurs.csv"
                delimiter=";"
              >
                Export CSV
              </JsonCSV>
            </v-btn>
            <v-bottom-sheet
              v-model="createUserToggle"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>add</v-icon>
                  Ajouter un utilisateur
                </v-btn>
              </template>
              <v-sheet
                class="text-center overflow-y-auto"
              >
                <div class="py-2 px-5 col">
                  <v-row justify="center">
                    <v-col
                      cols="12"
                    >
                      <div class="subtitle-1 text-center d-flex justify-center mb-8 flex-wrap">
                        <span>
                          Ajouter un utilisateur
                        </span>
                        <span
                          v-if="currentCdpeIsNational()"
                          class="ml-1"
                        >
                          pour le national
                        </span>
                        <span
                          v-else-if="currentConseilLocal && currentConseilLocal.id !== 0"
                          class="ml-1"
                        >
                          pour le conseil local {{ currentConseilLocal.nom }}
                        </span>
                        <span
                          v-else-if="currentCdpe"
                          class="ml-1"
                        >
                          pour le département {{ currentCdpe.nom }}
                        </span>
                      </div>
                    </v-col>
                    <v-col
                      v-if="!currentConseilLocal || currentConseilLocal.id === 0"
                      :xl="3"
                      :lg="4"
                      :md="5"
                      :sm="8"
                      :cols="10"
                    >
                      <v-form
                        ref="form"
                        @submit.prevent="validate"
                      >
                        <v-text-field
                          v-model="userToCreate.nom"
                          v-validate="'required|max:45'"
                          label="Nom"
                          required
                          data-vv-name="nom"
                          :error-messages="errors.collect('nom')"
                        />
                        <v-text-field
                          v-model="userToCreate.prenom"
                          v-validate="'required|max:45'"
                          label="Prénom"
                          required
                          data-vv-name="prénom"
                          :error-messages="errors.collect('prénom')"
                        />
                        <v-text-field
                          v-model="userToCreate.email"
                          v-validate="'required|email|max:255'"
                          label="Mail"
                          data-vv-name="email"
                          :error-messages="errors.collect('email')"
                        />
                        <v-select
                          v-model="userToCreate.role"
                          label="Rôle"
                          no-data-text="Aucun rôle trouvé"
                          :items="getRolesForCreate"
                          item-text="nom"
                          item-id="nom"
                          return-object
                        />
                        <v-btn
                          :disabled="!formValid"
                          color="success"
                          class="mr-4"
                          type="submit"
                        >
                          Enregister
                        </v-btn>
                        <v-btn
                          class="mr-4"
                          color="error"
                          @click="createUserToggle = !createUserToggle"
                        >
                          Fermer
                        </v-btn>
                      </v-form>
                    </v-col>
                    <v-col
                      v-else-if="getListParentsFiltered.length"
                      :xl="6"
                      :lg="8"
                      :md="10"
                      :cols="12"
                    >
                      <v-select
                        v-model="userToCreate.role"
                        label="Rôle"
                        no-data-text="Aucun rôle trouvé"
                        :items="getRolesForCreate"
                        item-text="nom"
                        item-id="nom"
                        return-object
                      />
                      <v-data-table
                        light
                        :headers="headersParents"
                        class="elevation-3"
                        loading-text="Chargement en cours"
                        no-data-text="Aucun parent responsable au sein du Conseil Local"
                        :items="getListParentsFiltered"
                        :sort-by="['nom']"
                        :items-per-page="10"
                        :footer-props="{
                          itemsPerPageText: 'Utilisateurs par page:',
                          itemsPerPageOptions: [10, 20, 50, 100, 200]
                        }"
                      >
                        <template v-slot:item.action="{ item }">
                          <v-btn
                            class="ma-2"
                            color="success"
                            @click="createUserParent(item)"
                          >
                            Crée l'utilisateur
                            <template v-slot:loader>
                              <span>Loading...</span>
                            </template>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col
                      v-else
                      :xl="6"
                      :lg="8"
                      :md="10"
                      :cols="12"
                      class="mb-10"
                    >
                      <v-alert type="warning">
                        Aucun parent responsable au sein du Conseil Local
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </v-col>
        </v-row>
        <v-slide-y-transition>
          <div>
            <v-row class="xs14">
              <v-col
                v-if="isUserNational"
                cols="12"
                md="6"
                class="text-right"
              >
                <v-autocomplete
                  v-model="currentCdpe"
                  :items="getFilterDepartementsWithNAT"
                  label="Département"
                  :item-text="item => `${item.code} - ${item.nom}`"
                  return-object
                  @change="changeCdpe"
                />
              </v-col>
              <v-col
                v-else
                cols="12"
              >
                <p>
                  Département : <b>{{ currentCdpe.nom }} </b>
                </p>
              </v-col>
              <v-col
                v-if="!isUserLocal"
                cols="12"
                md="6"
                class="text-right"
              >
                <v-autocomplete
                  v-model="currentConseilLocal"
                  :items="filterConseilsLocaux"
                  :item-text="item => `${item.code} - ${item.nom}`"
                  label="Conseil Local"
                  return-object
                  clearable
                  @change="changeConseilLocal"
                />
              </v-col>
              <v-col
                v-else
                cols="12"
              >
                <p>Conseil local : <b>{{ currentConseilLocal.nom }}</b></p>
              </v-col>
            </v-row>
          </div>
        </v-slide-y-transition>
        <v-data-table
          light
          :headers="headers"
          class="elevation-3"
          :loading="loading"
          loading-text="Chargement en cours"
          no-data-text="Aucun utilisateur trouvé"
          :items="usersByPerimetreId"
          :sort-by="['nom']"
          :items-per-page="50"
          :footer-props="{
            itemsPerPageText: 'Utilisateurs par page:',
            itemsPerPageOptions: [20, 50, 100, 200]
          }"
        >
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click.stop
                >
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    :to="{ name: 'edition-utilisateur', params: { userId: item.id }}"
                  >
                    <v-list-item-action
                      :key="item.id"
                    >
                      Modifier
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action
                      @click="deleteUser(item.id)"
                    >
                      Supprimer
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:item.bloque="{ item }">
            <v-simple-checkbox 
              v-model="item.bloque" 
              disabled
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { PERIMETRE_NATIONAL_ID } from './../../auth/typePerimetre.js';
  import JsonCSV from 'vue-json-csv';

export default {
  name: 'ListUsers',
  components: {
    JsonCSV,
  },
  data: () => ({
      createUserToggle: false,
      currentCdpe: null,
      currentConseilLocal: null,
      userToCreate: {
          'email': '',
          'nom': '',
          'perimetreId': 0,
          'prenom': '',
          'role': '',
      },
      headers: [
          {
              text: 'Action',
              sortable: true,
              value: 'action',
          },
          {
              text: 'Nom',
              sortable: true,
              value: 'nom',
          },
          {
              text: 'Prénom',
              sortable: true,
              value: 'prenom',
          },
          {
              text: 'Email',
              sortable: false,
              value: 'email',
          },
          {
            text: 'CL',
            sortable: true,
            value: 'perimetreName',
          },
          {
            text: 'Bloqué',
            sortable: false,
            value: 'bloque',
          },
      ],
      headersParents: [
          {
              text: 'Nom',
              sortable: true,
              value: 'personne.nom',
          },
          {
              text: 'Prénom',
              sortable: true,
              value: 'personne.prenom',
          },
          {
              text: 'Email',
              sortable: false,
              value: 'contact.email',
          },
          {
              text: 'Action',
              sortable: true,
              value: 'action',
          },
      ],
      rolesNationaux: [],
      rolesDepartementaux: [],
      rolesLocaux: [],
      roleNationalParDefaut: { nom: 'ROLE_NATIONAL' },
      roleDepartementalParDefaut: null,
      roleLocalParDefaut: null,
      filterConseilsLocaux: [],
  }),

  computed: {
      ...mapState('parametrages/utilisateur', {
          usersByPerimetreId: state => state.usersByPerimetreId,
          loading: state => state.loading,
      }),
      ...mapState('parametrages/role', {
        rolesByPerimetreId: state => state.rolesByPerimetreId,
        loadingRoles: state => state.loading,
      }),
      ...mapGetters('parametrages/utilisateur', [
          'getCurrentCdpe',
          'getCurrentConseilLocal',
          'getListParentsFiltered',
      ]),
      ...mapGetters('cdpe', [
          'getFilterDepartements',
      ]),
      ...mapGetters('conseilLocal', [
          'getFilterConseilsLocaux',
      ]),
      ...mapGetters('session', [
          'isUserNational',
          'isUserDepartemental',
          'isUserLocal',
          'getPerimetreUser',
      ]),
      getFilterDepartementsWithNAT() {
          return [
              {
                  id: PERIMETRE_NATIONAL_ID,
                  code: 'NAT',
                  nom: 'National',
              },
          ].concat(this.getFilterDepartements);
      },
      formValid() {
          // loop over all contents of the fields object and check if they exist and valid.
          let valid = Object.keys(this.fields).every(field => {
              return this.fields[field] && this.fields[field].valid;
          });
          //Save validation state in store, container will use it to desactivate save button
          // this.$store.commit('_parent/setFormValid', valid);
          return valid;
      },
      getRolesForCreate() {
        if (this.currentCdpeIsNational()) {
          return this.rolesNationaux;
        } else {
          if (this.currentConseilLocal) {
            return this.rolesLocaux;
          } else {
            return this.rolesDepartementaux;
          }
        }
      },
      getUsersForCsv() {
        return this.usersByPerimetreId.map(user => ({
            id: user.id,
            nom: user.nom,
            prenom: user.prenom,
            email: user.email,
            bloque: user.bloque ? 'OUI' : 'NON',
            perimetre: user.perimetreName,
          }));
      },
  },
  watch: {
      getFilterConseilsLocaux() {
        if (!this.currentCdpeIsNational()) {
            this.filterConseilsLocaux = [...this.getFilterConseilsLocaux];
            this.filterConseilsLocaux.unshift({ id: 0, code: '000000000', nom: 'TOUS LES CONSEILS LOCAUX' });
          } else {
            this.filterConseilsLocaux = [];
          }
      },
      getCurrentCdpe(perimetre) {
          this.userToCreate.perimetreId = perimetre.perimetreId;
      },
      rolesByPerimetreId(val){
        this.rolesNationaux = val.national;
        this.userToCreate.role = this.roleNationalParDefaut;
        if (this.rolesNationaux && val.national.length == 0) {
          this.rolesDepartementaux = val.departemental;
          this.roleDepartementalParDefaut = this.rolesDepartementaux.reduce((accumulator, currentValue) => {
            if (accumulator == null) {
              return currentValue;
            }
            return currentValue.nom.length < accumulator.nom.length  ? currentValue : accumulator;
          }, null);
          this.rolesLocaux = val.local;
          this.roleLocalParDefaut = this.rolesLocaux.reduce((accumulator, currentValue) => {
            if (accumulator == null) {
              return currentValue;
            }
            return currentValue.nom.length < accumulator.nom.length  ? currentValue : accumulator;
          }, null);
          this.userToCreate.role = this.roleDepartementalParDefaut;
        } else {
          this.rolesDepartementaux = [];
          this.rolesLocaux = [];
        }
      },
  },
  created() {
      this.initCurrentCdpe();
      this.userToCreate.perimetreId = this.currentConseilLocal ? this.currentConseilLocal.id : this.currentCdpe.id;
      if (this.getFilterDepartements.length < 1 && this.isUserNational) {
          this.loadFilterDepartements();
      }
  },

  methods: {
      ...mapActions('parametrages/utilisateur', {
          loadUsersByPerimetreId: 'loadUsersByPerimetreId',
          createUser: 'createUser',
          deleteUserById: 'deleteUser',
          createListParents: 'createListParents',
      }),
      ...mapActions('parametrages/role', [
        'loadRolesByPerimetreId',
      ]),
      ...mapActions('cdpe', [
          'loadFilterDepartements',
      ]),
      ...mapActions('conseilLocal', {
          loadFilterConseilsLocaux: 'loadFilterConseilsLocaux',
          resetFilterConseilsLocaux: 'resetState',
      }),
      initCurrentCdpe() {
          if (this.getCurrentCdpe && this.getCurrentCdpe.id) {
            this.currentCdpe = this.getCurrentCdpe;
          } else {
            if (this.isUserDepartemental || this.isUserNational) {
                this.currentCdpe = {
                    id: this.getPerimetreUser.perimetre_id,
                    code: this.getPerimetreUser.code,
                    nom: this.getPerimetreUser.nom,
                };
            } else {
                this.currentCdpe = null;
            }
          }
          if (this.currentCdpe) {
            this.loadRolesByPerimetreId(this.currentCdpe.id);
            if (this.currentCdpeIsNational()) {
              this.userToCreate.role = this.roleNationalParDefaut;
            } else {
              this.userToCreate.role = this.roleDepartementalParDefaut;
              this.loadFilterConseilsLocaux(this.currentCdpe.id);
            }
          }
          this.changeCdpeFromInit(this.currentCdpe);
      },
      changeCdpeFromInit(val) {
        this.initCurrentConseilLocal();
        if (this.currentConseilLocal && this.currentConseilLocal.id) {
          this.loadRolesByPerimetreId(this.currentCdpe.id).then(() => {
            this.userToCreate.role = this.roleLocalParDefaut;
          });
          return;
        }
        this.changeCdpe(val);
      },
      changeCdpe(val) {
          if (!val) {
              if (!this.isUserLocal) {
                  this.loadUsersByPerimetreId({ cdpe: null });
                  this.loadRolesByPerimetreId();
                  this.userToCreate.role = this.roleNationalParDefaut;
              }
              return;
          }
          if (this.currentCdpeIsNational()) {
            this.resetFilterConseilsLocaux();
          } else {
            this.loadFilterConseilsLocaux(this.currentCdpe.id);
          }
          this.loadUsersByPerimetreId({ cdpe: this.currentCdpe });
          this.loadRolesByPerimetreId(this.currentCdpe.id);
          if (this.currentCdpeIsNational()) {
            this.userToCreate.role = this.roleNationalParDefaut;
          } else {
            this.userToCreate.role = this.roleDepartementalParDefaut;
          }
      },
      initCurrentConseilLocal() {
        if (this.getCurrentConseilLocal && this.getCurrentConseilLocal.id) {
          this.currentConseilLocal = this.getCurrentConseilLocal;
          this.changeConseilLocal(this.currentConseilLocal);
        } else {
          if (this.isUserLocal) {
              this.currentConseilLocal = {
                  id: this.getPerimetreUser.perimetre_id,
                  code: this.getPerimetreUser.code,
                  nom: this.getPerimetreUser.nom,
              };
              this.currentCdpe = {
                  id: this.getPerimetreUser.perimetre_appartenance_id,
                  code: this.getPerimetreUser.perimetre_appartenance_code,
                  nom: this.getPerimetreUser.perimetre_appartenance_nom,
              };
              this.changeConseilLocal(this.currentConseilLocal);
          } else {
              this.currentConseilLocal = null;
          }
        }
      },
      changeConseilLocal(val) {
          if (!val) {
              if (!this.isUserLocal) {
                  this.loadUsersByPerimetreId({ cdpe:this.currentCdpe });
                  this.loadRolesByPerimetreId(this.currentCdpe.id);
                  this.userToCreate.role = this.roleDepartementalParDefaut;
              }
              return;
          }
          if (this.currentConseilLocal.id !== 0) {
            this.createListParents(this.currentConseilLocal.id);
          }
          this.loadUsersByPerimetreId({ cdpe:this.currentCdpe, conseilLocal: this.currentConseilLocal });
          this.userToCreate.role = this.roleLocalParDefaut;
      },
      validate(){
        this.$validator.validateAll();
        if (!this.formValid) {
          return;
        }
        this.userToCreate.perimetreId = this.currentConseilLocal ? this.currentConseilLocal.id : this.currentCdpe.id;
        this.userToCreate.role = this.userToCreate.role.nom;
        this.createUser(this.userToCreate).then(() => this.reinitCreatedUser());
      },
      createUserParent(parent){
          let queryUserToCreate = {
              email: parent.contact.email,
              nom: parent.personne.nom,
              perimetreId: this.currentConseilLocal.id,
              prenom: parent.personne.prenom,
              role: this.userToCreate.role.nom,
          };
          this.createUser(queryUserToCreate).then(() => this.reinitCreatedUser());
      },
      reinitCreatedUser() {
        this.createUserToggle = false;
        this.userToCreate.email = '';
        this.userToCreate.nom = '';
        this.userToCreate.prenom = '';
      },
      deleteUser(userId) {
        this.deleteUserById(userId);
      },
      currentCdpeIsNational() {
        return !this.currentCdpe || this.currentCdpe.id === PERIMETRE_NATIONAL_ID;
      },
  },
};
</script>
